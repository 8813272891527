import {CurrentUserInfoDecorator} from "../commands/user/types/CurrentUserInfoDecorator";
import {Pages} from "../../routing/Pages";
import {generatePath} from "react-router-dom";


export const isMessageButtonVisible = (pathname: string, user: CurrentUserInfoDecorator | null) => {
    return pathname !== Pages.REGISTER_BUSINESS_ACCOUNT && user && user.isClient;
};

export const isProfileButtonVisible = (pathname: string) => {
    return ![Pages.REGISTER_BUSINESS_ACCOUNT, Pages.LOGIN].includes(pathname as Pages)
};

export const isCreateNewAdButtonVisible = (pathname: string, user: CurrentUserInfoDecorator | null) => {
    return ![
        Pages.LOGIN,
        Pages.NEW_AD,
        Pages.REGISTER_BUSINESS_ACCOUNT
    ].includes(pathname as Pages) && (!user || user.isClient);
};

export const isSearchVisible = (pathname: string, user: CurrentUserInfoDecorator | null) => {
    return pathname === Pages.LANDING && !user?.isModerator && !user?.isAdmin
};

export const getFullPath = (path: string, params: Record<string, string>) => {
    return `${window.location.origin}${generatePath(path, params)}`
}