import {FC, ReactNode, useState} from "react";
import {ReactComponent as UserProfileIcon} from "./resources/UserProfile.svg";
import {CurrentUserInfoDecorator} from "../../../commands/user/types/CurrentUserInfoDecorator";
import "./styles/avatar.scss";

interface Props {
    onClick?: () => void;
    icon?: ReactNode;
    size?: "small" | "large";
    user?: CurrentUserInfoDecorator | null;
}

export const Avatar: FC<Props> = (props: Props) => {
    const {user} = props;
    const {onClick, icon, size = "small"} = props;
    const [isImageLoaded, setImageLoaded] = useState<boolean>(true);

    const handleImageLoad = () => {
        setImageLoaded(false);
    };
    const profileIcon: ReactNode = icon || (<UserProfileIcon/>);

    return (
        <div className={`avatar avatar--${size}`} onClick={onClick}>
            {
                (user?.photoUrl && isImageLoaded)
                    ? (<img src={user?.photoUrl ?? ""} onError={handleImageLoad} alt="avatar"/>)
                    : profileIcon
            }
        </div>
    );
};