import {CurrentUserInfo, Params, UserStatus} from "./CurrentUserInfo";
import {isEmpty} from "lodash-es";
import {User} from "firebase/auth";
import {PublicUserInfo} from "./PublicUserInfo";
import {UploadFile} from "antd/lib/upload/interface";
import {STORAGE_BUCKET} from "../../../../firebase/config";
import {Shop} from "../../../../types/integration/Shop";

export enum UserRole {
    ADMIN = "ADMIN",
    MODERATOR = "MODERATOR",
    SHOP = "SHOP",
    PHYSICAL_PERSON = "PHYSICAL_PERSON"
}

export const BUSINESS_ACCOUNT_NAME_PROPS: string = "?isBusinessAccount=true";

export const hasNotConfirmedBusinessAccount = (user?: User | null) => {
    return (user?.photoURL ?? "").includes(BUSINESS_ACCOUNT_NAME_PROPS);
};

export class CurrentUserInfoDecorator {
    src: CurrentUserInfo | undefined;
    firebaseUser: User | undefined;
    constructor(firebaseUser?: User, user?: CurrentUserInfo) {
        this.src = user;
        this.firebaseUser = firebaseUser;
    }

    get id() {
        return this.src?.id ?? this.firebaseUser?.uid;
    }

    get phoneNumber() {
        return this.firebaseUser?.phoneNumber ?? this.src?.phoneNumber;
    }

    get dbDisplayName() {
        return (this.src?.displayName || this.firebaseUser?.displayName) ?? "";
    }

    get displayName(): string {
        if (this.hasShop) {
            return this.shop?.name ?? "";
        }

        if (this.src?.displayName) {
            return this.src?.displayName;
        }

        if (this.firebaseUser?.displayName) {
            return this.firebaseUser?.displayName;
        }

        if (this.isClient) {
            return "Користувач"
        }

        if (this.isAdmin) {
            return "Адміністратор";
        }

        if (this.isModerator) {
            return "Модератор";
        }

        return ""
    }

    get photoUrl() {
        if (this.hasShop) {
            return this.shop?.logoUrl;
        }
        return this.src?.photoURL || this.firebaseUser?.photoURL;
    }

    hasFavorite(adId: string) {
        return (this.src?.favoriteAds ?? []).includes(adId);
    }

    get hasFavorites(): boolean {
        return !isEmpty(this.src?.favoriteAds);
    }

    get numberOfFavorites(): number {
        return this.src?.favoriteAds?.length ?? 0;
    }

    get isAdmin(): boolean {
        return this.src?.customClaims?.roles?.includes(UserRole.ADMIN)
    }

    get isModerator(): boolean {
        return this.src?.customClaims?.roles?.includes(UserRole.MODERATOR)
    }

    get isClient(): boolean {
        return !this.isAdmin && !this.isModerator;
    }

    get isNotClient(): boolean {
        return this.isModerator || this.isAdmin;
    }

    get favouriteAds(): string[] {
        return this.src?.favoriteAds ?? [];
    }

    get email(): string {
        return this.src?.email ?? this.firebaseUser?.email ?? "";
    }

    get publicInfo(): PublicUserInfo {
        return {
            id: this.id ?? "",
            displayName: this.displayName,
            photoURL: this.firebaseUser?.photoURL ?? "",
            creationTime: this.firebaseUser?.metadata.creationTime ?? ""
        }
    }

    get isDisabled(): boolean {
        return !isEmpty(this.src?.deletedOn) || [UserStatus.DELETED, UserStatus.DISABLED].includes(this.src?.status as UserStatus);
    }

    get status() {
        if (this.isDisabled) {
            return "Заблокований"
        }

        return "Активний";
    }

    get role() {
        return this.src?.customClaims?.roles?.[0] ?? UserRole.PHYSICAL_PERSON;
    }

    get displayRoles() {
        return this.src?.customClaims?.roles?.map((item: UserRole) => {
            switch (item) {
                case UserRole.ADMIN:
                    return "Адміністратор";
                case UserRole.MODERATOR:
                    return "Модератор";
                case UserRole.SHOP:
                    return "Магазин";
            }

            return "Фізична Особа";
        }).join(",");
    }

    get isNotConfirmedBusinessAccount(): boolean {
        return hasNotConfirmedBusinessAccount(this.firebaseUser);
    }

    get shop(): Shop | undefined {
        return this.src?.shop;
    }
    get hasShop(): boolean {
        const hasRole: boolean = (this.src?.customClaims?.roles ?? []).includes(UserRole.SHOP);
        return hasRole && !isEmpty(this.shop);
    }

    get photoURL(): string | null {
        return this.firebaseUser?.photoURL ?? null;
    }

    get photoFile(): UploadFile[] {
        return this.photoURL ? [{
            uid: "1",
            status: "done",
            name: "avatar",
            url: this.photoURL,
        }] : [];
    }

    get photoStorageUrl(): string | null {
        if (!this.photoURL) {
            return null;
        }
        const isStorageUrl: boolean = this.photoURL.includes(STORAGE_BUCKET);
        if (isStorageUrl) {
            return this.photoURL.split(`/${STORAGE_BUCKET}`)[1];
        }
        return null;
    }

    get params(): Params | undefined {
        return this.src?.params;
    }
}