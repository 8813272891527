import {Navigate, Route, Routes} from "react-router-dom";
import React, {FC, lazy} from "react";
import {Pages} from "./Pages";
import {Landing} from "../content/landing/Landing";
import {LazyPage} from "../content/lazyPage/LazyPage";
import {useCurrentUser} from "../common/commands/user/useCurrentUser";
import {NavigationProvider} from "../common/navigation/NavigationContext";

const SearchPage = lazy(() => import("../content/searchPage/SearchPage"));
const Login = lazy(() => import("../content/login/Login"));
const UserProfilePage = lazy(() => import("../content/userProfilePage/UserProfilePage"));
const NewAdPage = lazy(() => import("../content/newAdPage/NewAdPage"));
const DetailedSearch = lazy(() => import("../content/detailedSearch/DetailedSearch"));
const AdDetailsPage = lazy(() => import("../content/adDetailsPage/AdDetailsPage"));
const EditPage = lazy(() => import("../content/editPage/EditPage"));
const ModerationPage = lazy(() => import("../content/moderationPage/ModerationPage"));
const AdministrationPage = lazy(() => import("../content/administration/administrationPage/AdministrationPage"));
const AdministrationUserDetails = lazy(() => import("../content/administration/administrationUserDetails/AdministrationUserDetails"));
const RegisterBusinessAccountPage = lazy(() => import("../content/registerBusinessAccountPage/RegisterBusinessAccountPage"));
const CreatePartnerPage = lazy(() => import("../content/administration/createPartnerPage/CreatePartnerPage"));
const PartnerDetailsPage = lazy(() => import("../content/partnerDetailsPage/PartnerDetailsPage"));
const AboutPage = lazy(() => import("../content/about/AboutPage"));
const PublicContract = lazy(() => import("../content/law/legalAspects/publicСontract/PublicContract"));
const PrivacyPage = lazy(() => import("../content/law/legalAspects/privacy/PrivacyPage"));
const LegalAspects = lazy(() => import("../content/law/legalAspects/LegalAspects"));
const CookiesPage = lazy(() => import("../content/law/legalAspects/cookiesPage/CookiesPage"));
const DeletePersonalData = lazy(() => import( "../content/law/legalAspects/deletePersonalData/DeletePersonalData"));
const PaymentInfo = lazy(() => import( "../content/law/legalAspects/payment/PaymentInfo"));

export const AppRoutes: FC = () => {
    const [currentUser] = useCurrentUser();
    return (
        <NavigationProvider>
            <Routes>
                <Route path={Pages.LOGIN} element={<LazyPage component={Login}/>}/>
                {allUsersRoutes()}
                {!currentUser && getNotAuthorizedUserRoutes()}
                {currentUser?.isNotConfirmedBusinessAccount && getCreateBusinessAccountRouter()}
                {(currentUser?.isClient && !currentUser?.isNotConfirmedBusinessAccount) && getClientRoutes()}
                {currentUser?.isModerator && getModeratorRoutes()}
                {currentUser?.isAdmin && getAdminRoutes()}
            </Routes>
        </NavigationProvider>
    );
}

const getNotAuthorizedUserRoutes = () => (
    <>
        <Route path={Pages.LANDING} element={<Landing/>}/>
        <Route path={Pages.SEARCH} element={<LazyPage component={SearchPage}/>}/>
        <Route path={Pages.DETAILED_SEARCH} element={<LazyPage component={DetailedSearch}/>}/>
        <Route path={Pages.AD_PUBLIC_DETAILS} element={<LazyPage component={AdDetailsPage}/>}/>
        <Route path={Pages.PARTNER_DETAILS} element={<LazyPage component={PartnerDetailsPage}/>}/>
    </>
);

const getClientRoutes = () => (
    <>
        {getNotAuthorizedUserRoutes()}
        <Route path={Pages.EDIT_AD} element={<LazyPage component={EditPage}/>}/>
        <Route path={Pages.USER_PROFILE} element={<LazyPage component={UserProfilePage}/>}/>
        <Route path={Pages.NEW_AD} element={<LazyPage component={NewAdPage}/>}/>
    </>
);

const getModeratorRoutes = () => (
    <>
        <Route path="/" element={<Navigate to={Pages.MODERATION}/>}/>
        <Route path={Pages.MODERATION} element={<LazyPage component={ModerationPage}/>}/>
        <Route path="*" element={<Navigate to={Pages.MODERATION}/>}/>
    </>
);

const getAdminRoutes = () => (
    <>
        <Route path="/" element={<Navigate to={Pages.ADMINISTRATION}/>}/>
        <Route path={Pages.ADMINISTRATION} element={<LazyPage component={AdministrationPage}/>}/>
        <Route path={Pages.ADMINISTRATION_USER_DETAILS} element={<LazyPage component={AdministrationUserDetails}/>}/>
        <Route path={Pages.AD_PUBLIC_DETAILS} element={<LazyPage component={AdDetailsPage}/>}/>
        <Route path={Pages.CREATE_PARTNER} element={<LazyPage component={CreatePartnerPage}/>}/>
        <Route path={Pages.PARTNER_DETAILS} element={<LazyPage component={PartnerDetailsPage}/>}/>
        <Route path="*" element={<Navigate to={Pages.ADMINISTRATION}/>}/>
    </>
);

const getCreateBusinessAccountRouter = () => (
    <>
        <Route path="/" element={<Navigate to={Pages.REGISTER_BUSINESS_ACCOUNT}/>}/>
        <Route path={Pages.REGISTER_BUSINESS_ACCOUNT} element={<LazyPage component={RegisterBusinessAccountPage}/>}/>
        <Route path="*" element={<Navigate to={Pages.REGISTER_BUSINESS_ACCOUNT}/>}/>
    </>
);

const allUsersRoutes = () => (
    <>
        <Route path={Pages.ABOUT} element={<LazyPage component={AboutPage}/>}/>
        <Route path={Pages.PUBLIC_CONTRACT} element={<LazyPage component={PublicContract}/>}/>
        <Route path={Pages.PRIVACY} element={<LazyPage component={PrivacyPage}/>}/>
        <Route path={Pages.LEGAL_ASPECTS} element={<LazyPage component={LegalAspects}/>}/>
        <Route path={Pages.COOKIES} element={<LazyPage component={CookiesPage}/>}/>
        <Route path={Pages.DELETE_PERSONAL_DATA} element={<LazyPage component={DeletePersonalData}/>}/>
        <Route path={Pages.PAYMENT_INFO} element={<LazyPage component={PaymentInfo}/>}/>
    </>
)

