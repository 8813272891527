import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

type NavigationContextType = {
    navigationHistory: string[];
};

export const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
    const location = useLocation();
    const [navigationHistory, setNavigationHistory] = useState<string[]>([]);

    useEffect(() => {
        setNavigationHistory((prevHistory) => [...prevHistory, location.pathname]);
    }, [location]);

    return (
        <NavigationContext.Provider value={{ navigationHistory }}>
            {children}
        </NavigationContext.Provider>
    );
};