import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Carousel, Skeleton} from "antd";
import {Link} from "react-router-dom";
import {Pages} from "../../../routing/Pages";
import GetPartnersPublicInfoCommand from "./commands/GetPartnersPublicInfoCommand";
import {isEmpty} from "lodash-es";
import {PublicPartnerPageInfo} from "../../../types/integration/Partners";
import {Screen} from "../screen/Screen";
import {useScreenWidth} from "../../hooks/useScreenWidth";
import {Image} from "../images/image/Image";
import {getThumbnailPath} from "../../utils/ImagesUtils";
import {getFullPath} from "../../utils/LocationUtils";
import "./styles/partnersCarousel.scss";

const getSlidesToShow = (screenWidth: number, size: number) => {
    if (Screen.sm(screenWidth)) {
        return 2 > size ? size : 2;
    }

    if (Screen.xs(screenWidth)) {
        return 1 > size ? size : 1;
    }

    return 3 > size ? size : 3;
};
const slidesToScroll = (slidesToShow: number, size: number): number => {
    if (size === 0) {
        return 0;
    }
    if (size % slidesToShow === 0) {
        return slidesToShow;
    }
    return size % slidesToShow;
};

export const PartnersCarousel: FC = () => {
    const [partners, setPartners] = useState<PublicPartnerPageInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const screenWidth = useScreenWidth();
    const loadPartnersPublicInfo = async () => {
        try {
            const partners: PublicPartnerPageInfo[] = await new GetPartnersPublicInfoCommand().execute();
            setPartners(partners);
        } finally {
            setLoading(false);
        }
    };

    const slidesToShow = getSlidesToShow(screenWidth, partners.length);

    useEffect(() => {
        loadPartnersPublicInfo();
    }, []);

    if (isEmpty(partners)) {
        return null;
    }

    return loading
        ? (<Skeleton paragraph={{rows: 3}} active/>)
        : (
            <div className="partners-carousel">
                <Carousel slidesToShow={getSlidesToShow(screenWidth, partners.length)}
                          slidesToScroll={slidesToScroll(slidesToShow, partners.length)} adaptiveHeight={false}
                          autoplay={true}>
                    {partners.map((item: PublicPartnerPageInfo) => {
                        const {images, name, description} = item;
                        const [image = ""] = images || [];
                        return (
                            <Link to={getFullPath(Pages.PARTNER_DETAILS, {partnerId: item.id})}
                                  className="partners-carousel__item" key={item.id}>
                                <div className="partners-carousel__inner">
                                    <Image height="150px" src={getThumbnailPath(image)} alt={name}/>
                                    <div className="partners-carousel__info">
                                        <h6>
                                            {name}
                                        </h6>
                                        <p>
                                            {description}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </Carousel>
            </div>
        );
};